import * as React from 'react';

import './Card.css';

type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  title: string;
  description?: string;
  callOuts: string[];
  headerImage: React.ReactNode;
};

/**
 * Displays Card
 */
export const Card = ({
  title,
  description,
  callOuts,
  headerImage,
  ...props
}: React.PropsWithChildren<CardProps>) => {
  return (
    <div className={`card`} {...props}>
      <h4>{title}</h4>
      {headerImage}
      {callOuts && callOuts.length > 0 && (
        <ul>
          {callOuts.map((callOut, index) => (
            <li key={index}>{callOut}</li>
          ))}
        </ul>
      )}
      {description && <p className="description">{description}</p>}
    </div>
  );
};
