import * as React from 'react';

import './Testimonial.css';

type TestimonialProps = React.HTMLAttributes<HTMLDivElement> & {
  name: string;
  index: number;
  title: string;
  quote: string;
  image?: React.ReactNode;
};

/**
 * Displays Testimonial
 */
export const Testimonial = ({
  name,
  index,
  title,
  quote,
  image,
  ...props
}: React.PropsWithChildren<TestimonialProps>) => {
  return (
    <div className={`testimonial testimonial-${index}`} {...props}>
      <div className="--quote quote">{quote}</div>
      <div className="--person">
        <div className="--image">{image}</div>
        <div className="--details">
          <h6>{name}</h6>
          <p>{title}</p>
        </div>
      </div>
    </div>
  );
};
