import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import './OtherSlider.css';

type SliderProps = React.HTMLAttributes<HTMLDivElement> & {
  color?: Colors;
  titles: string[];
  callOuts: string[][];
  images: React.ReactElement[];
};

/**
 * Displays Slider
 */
export const OtherSlider = ({
  color,
  titles,
  callOuts,
  images,
  ...props
}: React.PropsWithChildren<SliderProps>) => {
  const [sliderIndex, setSliderIndex] = React.useState(0);

  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    console.log('gsap', gsap);
  }, []);

  return (
    <div className={`--otherSlider ${color}`} {...props}>
      {/* <div className="progress-bar"></div> */}

      <div className="--tabs">
        {titles.map((title, index) => (
          <div className={sliderIndex === index ? '--active' : ''} key={index}>
            <div className="--expand-image">{images[index]}</div>
            <div className="--break"></div>
            <button className="--header" onClick={() => setSliderIndex(index)}>
              <h4>{title}</h4>
              <ul>
                {callOuts[index].map((callOut, index) => (
                  <li key={index}>{callOut}</li>
                ))}
              </ul>
            </button>
          </div>
        ))}
      </div>
      <div className="--images">
        {images.map((image, index) => (
          <div key={index} className={sliderIndex === index ? '--active' : ''}>
            {image}
          </div>
        ))}
      </div>
    </div>
  );
};
